<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <futuro-logo />
        <futuro-text class="ml-1" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="sideImg"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Hora de criar sua senha 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Informe o código de 8 caracteres recebido por e-mail e sua senha, que deve ter no mínimo 8 caracteres, contendo letras e números, ok?
          </b-card-text>

          <b-form @submit.prevent="onSubmit">
            <b-form-group
              label="Token recebido por e-mail"
              label-for="token" 
              class="mb-1"
            >
              <b-form-input
                id="token"
                v-model="token"
                name="token"
                :class="{ 'is-invalid': v$.token.$error }"
                placeholder="Exemplo: a58s34hs"
              />
              <div class="invalid-feedback">
                <span v-if="v$.token.$invalid">
                  Você deve informar o token de 8 caracteres recebido por e-mail
                </span>
              </div>
            </b-form-group>

            <b-form-group
              label="Nova senha"
              label-for="password"
              class="mb-1"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  id="password"
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :class="{ 'is-invalid': v$.password.$error }"
                  name="password"
                  placeholder="Nova senha"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small v-if="v$.password.$error && v$.password.$invalid" class="text-danger">
                Senha inválida
              </small>
            </b-form-group>

            <b-form-group
              label="Confirme sua senha"
              label-for="password-confirmation"
              class="mb-1"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  id="password-confirmation"
                  v-model="passwordConfirmation"
                  class="form-control-merge"
                  :type="passwordConfirmationFieldType"
                  :class="{ 'is-invalid': v$.passwordConfirmation.$error }"
                  name="password-confirmation"
                  placeholder="Confirme sua senha"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordConfirmationToggleIcon"
                    @click="togglePasswordConfirmationVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small v-if="v$.passwordConfirmation.$error && v$.passwordConfirmation.$invalid" class="text-danger">
                As senhas não conferem
              </small>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="loading"
            >
              {{ loading ? 'Aguarde...' : 'Cadastrar nova senha' }}
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <b-link :to="{name:'account-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Voltar para o login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import FuturoLogo from '@core/layouts/components/Logo.vue'
import FuturoText from '@core/layouts/components/LogoText.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { togglePasswordVisibility, togglePasswordConfirmationVisibility } from '@core/mixins/ui/forms'
import useVuelidate from '@vuelidate/core'
import { required, sameAs } from '@vuelidate/validators'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { stringIsAlphanumeric } from '@/helpers/validators'
import * as types from '../store/types'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    FuturoLogo,
    FuturoText
  },
  data() {
    return {
      token: '',
      password: '',
      passwordConfirmation: '',
      loading: false,
      sideImg: require('@/assets/images/pages/login-v2.svg')
    }
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmationVisibility],
  validations() {
    return {
      token: { 
        required,
        length(value) {
          return value.length === 8;
        }
      },
      password: {
        required,
        minLength(value) {
          return value.length >= 8;
        },
        valid(value) {
          return stringIsAlphanumeric(value)
        }
      },
      passwordConfirmation: { required, sameAs: sameAs(this.password) }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConfirmationToggleIcon() {
      return this.passwordConfirmationFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    ...mapActions({
      resetPassword: types.SET_NEW_PASSWORD,
    }),
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return
      this.loading = true
      const {
        token,
        password
      } = this
      this.resetPassword({ token, password })
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Senha cadastrada",
              text: 'Sua senha foi cadastrada com sucesso!',
              icon: "CoffeeIcon",
              variant: "success",
            },
          })
          this.$router.push({ name: 'account-login' })
        })
        .catch(err => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: 'Token inválido. Retorne à tela de login e escolha a opção Esqueceu a senha? para obter um novo token.',
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
